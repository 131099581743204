<template>
  <div class="userAccessList">
    <div
      :class="item.userTypeName + ' userItem '"
      v-for="(item, index) of historyList"
      :key="index"
    >
      <h4>{{ item.fullName }} ({{ item.code }})</h4>
      <h6>{{ item.phone }}</h6>
      <h5>{{ item.lastMessage }}</h5>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "allCorrespondencesHistory",
  props: ["searchText", "userType"],
  data() {
    return {
      loading: false,
      loadingText: "در حال دریافت اطلاعات...",
      historyList: [],
    };
  },
  watch: {
    searchText() {
      this.fetchData();
    },
    userType() {
      this.fetchData();
    },
  },
  methods: {
    ...mapActions("correspondences", ["GetUserChatHistory"]),
    async fetchData() {
      this.loading = true;
      let result = await this.GetUserChatHistory(
        {},
        { userType: this.userType, searchText: this.searchText }
      );
      this.loading = false;
      console.log("result",result);
      if (result) {
        this.historyList = [];
        result.forEach((element) => {
          this.historyList.push({
            id: element.id,
            fullName: element.fullName,
            code: element.code,
            phone: element.phone,
            lastMessage: element.lastMessage,
            userTypeName: element.userTypeName,
            userType: element.userType,
          });
        });
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: "خطا در دریافت اطلاعات <br /><br />",
          type: "error",
        });
      }
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style scoped>
.userAccessList {
  height: 80vh;
  width: 18vw;
  overflow-y: auto;
  overflow-x: hidden;
}
.userAccessList::-webkit-scrollbar {
  width: 8px;
}
.userAccessList::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 20px;
}
.userAccessList::-webkit-scrollbar-thumb {
  background: #a1a1a1;
  border-radius: 10px;
}
.userAccessList::-webkit-scrollbar-thumb:hover {
  background: rgb(83, 83, 83);
}
.userItem {
  width: 96%;
  height: 6vh;
  margin: 10px 1px 0px 0px;
  cursor: pointer;
  padding: 4px;
  background-color: #e5e5e5;
}
.userItem h4 {
  float: right;
  font-size: 16px;
  text-align: center;
  width: 100%;
}
.userItem h6 {
  float: right;
  width: 30%;
}
.userItem h5 {
  font-size: 10px;
  margin: 1px 8px 0px 0px;
  color: #818181;
  width: 62%;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  background-color: transparent;
}
.selectedUserForChat {
  background-color: #07a13a;
  color: #fff;
  margin-right: 10px;
}
.selectedUserForChat h5 {
  color: #fff;
}
.advicer {
  /* background-color: #ff880034; */
  border-right: 3px solid #f80;
}
.farmUser {
  /* background-color: #acff2f3b; */
  border-right: 3px solid #80d300;
} 
.agent {
  /* background-color: #e5535327; */
  border-right: 3px solid #e55353;
}
.farmer {
  /* background-color: #e5535327; */
  border-right: 3px solid #e3e553;
}
</style>